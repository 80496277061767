import { graphql } from "gatsby";
import React from "react";
import BlogControls from "../components/BlogControls";
import BlogPost from "../components/BlogPost";

const BlogPostTemplate = props => {
  const {
    data: {
      allWordpressPost: { totalCount, edges },
      wordpressPost: {
        id,
        title,
        content,
        date,
        slug,
        acf: { dynamic_fields_post },
      },
    },
    pageContext,
  } = props;

  const index = edges.findIndex(post => post.node.id === id);
  const prev = edges[index - 1];
  const next = edges[index + 1];
  const prevExist = index - 1 >= 0;
  const nextExist = index + 1 <= totalCount - 1;

  return (
    <>
      <BlogPost
        title={title}
        date={date}
        content={content}
        dynamic={dynamic_fields_post}
        slug={slug}
        pageContext={pageContext}
      />
      <BlogControls
        prev={prevExist}
        next={nextExist}
        currPath={pageContext.lang === "en_US"}
        titlePrev={prevExist && prev.node.title}
        excerptPrev={prevExist && prev.node.excerpt}
        linkPrev={prevExist && prev.node.slug}
        titleNext={nextExist && next.node.title}
        excerptNext={nextExist && next.node.excerpt}
        linkNext={nextExist && next.node.slug}
      />
    </>
  );
};

export default BlogPostTemplate;

export const query = graphql`
  fragment WordPressAcf_images_group on wordpressPost {
    id
  }
  query($id: String, $lang: String) {
    allWordpressPost(
      filter: {
        title: { regex: "/^((?!DO NOT DELETE THIS POST).)*$/" }
        wpml_current_locale: { eq: $lang }
      }
    ) {
      totalCount
      edges {
        node {
          id
          title
          excerpt
          slug
          yoast_head
        }
      }
    }
    wordpressPost(wpml_current_locale: { eq: $lang }, id: { eq: $id }) {
      slug
      yoast_head
      id
      title
      content
      type
      date(formatString: "DD, MMM, YYYY")
      acf {
        dynamic_fields_post {
          __typename
          ... on WordPressAcf_images_group {
            images_group {
              item_image_1 {
                localFile {
                  childImageSharp {
                    fluid(maxHeight: 800, quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
              item_image_2 {
                localFile {
                  childImageSharp {
                    fluid(maxHeight: 800, quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
              item_image_3 {
                localFile {
                  childImageSharp {
                    fluid(maxHeight: 800, quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
          ... on WordPressAcf_inside_content_heading {
            inside_content_heading
          }
          ... on WordPressAcf_inside_content_image {
            id
            inside_content_image {
              localFile {
                childImageSharp {
                  fluid(maxHeight: 800, quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
          ... on WordPressAcf_text_partition {
            id
            text_partition
          }
          ... on WordPressAcf_categories {
            id
            categories
          }
        }
      }
    }
  }
`;
