import React from "react";
import ReactHtmlParser from "react-html-parser";
import Img from "gatsby-image";
import Fade from "react-reveal/Fade";
import Facebook from "../../static/assets/facebook.svg";
import Linkedin from "../../static/assets/linkedin.svg";
import { v4 as uuid } from "uuid";

const BlogPost = ({ title, date, content, dynamic, slug, pageContext }) => {
  let categories = null;
  const blog = dynamic.map(item => {
    categories = item.categories;
    if (
      item.__typename === "WordPressAcf_inside_content_image" &&
      item.inside_content_image &&
      item.inside_content_image.localFile &&
      item.inside_content_image.localFile.childImageSharp
    ) {
      return (
        <div key={uuid()} className="image-wrap">
          <Img
            fluid={item.inside_content_image.localFile.childImageSharp.fluid}
          />
        </div>
      );
    }
    if (item.__typename === "WordPressAcf_text_partition") {
      return (
        <div key={uuid()} className="blog-container">
          <div className="text-partition">
            {ReactHtmlParser(item.text_partition)}
          </div>
        </div>
      );
    }
    if (item.__typename === "WordPressAcf_images_group" && item.images_group) {
      return (
        <div key={uuid()} className="image-wrap">
          <div className="row">
            {item.images_group.item_image_1 && (
              <Fade bottom delay={100}>
                <div className="col-md-4">
                  <Img
                    fluid={
                      item.images_group.item_image_1.localFile.childImageSharp
                        .fluid
                    }
                  />
                </div>
              </Fade>
            )}
            {item.images_group.item_image_2 && (
              <Fade bottom delay={200}>
                <div className="col-md-4">
                  <Img
                    fluid={
                      item.images_group.item_image_2.localFile.childImageSharp
                        .fluid
                    }
                  />
                </div>
              </Fade>
            )}
            {item.images_group.item_image_3 && (
              <Fade bottom delay={300}>
                <div className="col-md-4">
                  <Img
                    fluid={
                      item.images_group.item_image_3.localFile.childImageSharp
                        .fluid
                    }
                  />
                </div>
              </Fade>
            )}
          </div>
        </div>
      );
    }
    if (item.__typename === "WordPressAcf_inside_content_heading") {
      return (
        <div key={uuid()} className="blog-container">
          <h3 className="inside_content_heading">
            {item.inside_content_heading}
          </h3>
        </div>
      );
    }
    return;
  });

  let location;
  if (pageContext.lang === "en_US") {
    location = `https://helmut.de/en/blog/${slug}`;
  } else {
    location = `https://helmut.de/blog/${slug}`;
  }

  return (
    <div className="blog">
      <div className="blog-container">
        <h2>{title}</h2>
        <div className="blog-container-wrap">
          <p>{categories}</p>|<p>{date}</p>
        </div>
        {ReactHtmlParser(content)}
      </div>
      {blog}
      <div className="blog-container parlast">
        <div className="blog-container-social-wrap">
          <h4>Share this post</h4>
          <div className="blog-container-social">
            <a href={`https://www.facebook.com/sharer.php?u=${location}`}>
              <Facebook />
            </a>
            <a
              href={`https://www.linkedin.com/shareArticle?url=${location}&title=${title}`}
            >
              <Linkedin />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogPost;
