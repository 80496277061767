import { Link } from 'gatsby';
import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import Fade from 'react-reveal/Fade';

const BlogControls = ({
  titlePrev, titleNext, excerptPrev, excerptNext, linkPrev, linkNext, currPath, prev, next,
}) => (
  <div className="blog-controls">
    <div className="row">
      {prev ? (
        <div className="col-md-6">
          <div className="blog-controls-item">
            <Fade bottom>
              <h3>{ReactHtmlParser(titlePrev)}</h3>
              <div className="blog-controls-item-down">
                {ReactHtmlParser(excerptPrev)}
                <Link to={currPath ? `/en/blog/${linkPrev}/` : `/blog/${linkPrev}/`} className="pagination-btn">
                  {currPath ? 'READ' : 'LESEN'}
                </Link>
              </div>
            </Fade>
          </div>
        </div>
      ) : null}
      {next ? (
        <div className="col-md-6">
          <div className="blog-controls-item">
            <Fade bottom>
              <h3>{ReactHtmlParser(titleNext)}</h3>
              <div className="blog-controls-item-down">
                {ReactHtmlParser(excerptNext)}
                <Link to={currPath ? `/en/blog/${linkNext}/` : `/blog/${linkNext}/`} className="pagination-btn">
                  {currPath ? 'READ' : 'LESEN'}
                </Link>
              </div>
            </Fade>
          </div>
        </div>
      ) : null}
    </div>
  </div>
);

export default BlogControls;
